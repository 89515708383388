.adminTextarea {
  width: 100%;
  height: auto;

  & > div > span {
    display: block;
    font-family: var(--ptSans);
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 21px;
    display: flex;
    align-items: center;
  }

  & > div > textarea {
    width: 100%;
    min-width: 400px;
    min-height: 45px;
    background: #ffffff;
    border: 1px solid var(--borderOutlineColor);
    box-sizing: border-box;
    border-radius: var(--borderRadius, 4px);
    outline: none;
    padding: 8px 15px;
    display: flex;
    align-items: center;
    gap: 15px;
    resize: none;

    &::placeholder {
      font-family: PT Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 21px;
      display: flex;
      align-items: center;
      color: var(--placeholderColor);
    }
  }
}
