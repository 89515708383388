:root {
  --primaryColor2: #fe4d55;
  --primaryColor: #ec3c31;
  --primaryColor50: #ff9380;
  --primaryShadeColor: #fdebe8;
  --primaryColor25: var(--primaryShadeColor, #fdebe8);
  --borderOutlineColor: #cccccc;
  --placeholderColor: #999999;
  --textColor: #555555;
  --bodyBackground: #f2f4f8;
  --successGreen: #00ff84;
  --dangerRed: #ffc0c7;
  --questionBG: #e4e8ee;
  --fontPrimary: #002333;
  --darkPurpleColor: #221638;
  --active: #2dbf64;
  --ptSans: 'PT Sans', sans-serif;
  --borderRadius: 12px;
}

.page-title {
  font-size: 2rem;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;

  & > span {
    display: flex;
    gap: 1rem;
  }

  & > span > small {
    font-size: 10px;
    margin-left: 5px;
  }
}

.flex-1 {
  flex: 1;
}

button:disabled {
  background: lightGrey !important;
  border: 1px solid lightGrey !important;
}

.adminTable__pagination .selected {
  background: var(--primaryColor);
  color: #ffffff;
}

.accordion-button {
  background: #f3f3f3;
  border: none;
  padding: 1rem;
  margin: 10px 0;
  transition: background-color 0.2s;

  &:hover {
    background: #e2e2e2;
  }
}

.accordion-button:not(.collapsed) {
  border: none;
}

.accordion-collapse.show {
  border-radius: 0 !important;
}

.border-radius {
  border-radius: var(--borderRadius, 8px);
}

.cursor-pointer {
  cursor: pointer;
}

:root {
  --plyr-color-main: var(--primaryColor);
  // --plyr-control-icon-size: 24px;
  // --plyr-control-spacing: 18px;
  // --plyr-range-track-height: 10px;
  // --plyr-range-thumb-height: 24px;
  // --plyr-menu-background: #ffffffea;
  // --plyr-font-size-menu: 14px;
  --plyr-overlay-background-opacity: 0;
  --youtube-icon-blur: 8px;
}

.plyr__poster {
  display: block !important;
  position: relative;
}

.plyr::after,
.plyr::before {
  content: '';
  display: flex;
  position: absolute;
  z-index: 1;
}
.plyr::after {
  inset: 0;
  background-color: #000;
  opacity: var(--plyr-overlay-background-opacity);
}
// use this to hide youtube icon
.plyr::before {
  // content: 'Precise Mentorship';
  // color: var(--primaryColor);
  // text-shadow: white 0.95px 0.95px 1.6px;
  // line-height: 1.1;
  // font-size: 10px;
  // display: flex;
  // // border: 2px solid red;
  // align-items: center;
  // justify-content: center;

  // magic numbers
  height: 30px;
  width: 120px;
  bottom: 12px;
  right: 16px;
  backdrop-filter: blur(var(--youtube-icon-blur));
  border-radius: var(--borderRadius, 4px);
}

.ytp-watermark {
  display: none;
}
@media screen and (max-width: 576px) {
  .plyr__controls {
    display: grid !important;
    grid-template-columns: repeat(11, 1fr);
    grid-template-areas:
      'p p p p p p p p p p p'
      'a b c d e v v v v s f';
  }

  .plyr__time + .plyr__time {
    display: block !important;
  }

  .plyr__menu {
    margin-inline: auto !important;
  }

  // rewind
  .plyr__controls > :nth-child(1) {
    grid-area: a;
    margin-right: unset !important;
  }

  // play/pause
  .plyr__controls > :nth-child(2) {
    grid-area: b;
  }

  // fast forward
  .plyr__controls > :nth-child(3) {
    grid-area: c;
  }

  .plyr__controls > :nth-child(1),
  .plyr__controls > :nth-child(2),
  .plyr__controls > :nth-child(3) {
    display: flex;
    justify-content: center;
  }

  // progress bar
  .plyr__controls > :nth-child(4) {
    grid-area: p;
  }

  // played time
  .plyr__controls > :nth-child(5) {
    grid-area: d;
  }

  // total time
  .plyr__controls > :nth-child(6) {
    grid-area: e;
  }

  // volume
  .plyr__controls > :nth-child(7) {
    grid-area: v;
    margin-left: auto !important;
  }
  .plyr__controls > :nth-child(7) input[type='range'] {
    display: none;
  }

  // settings
  .plyr__controls > :nth-child(8) {
    grid-area: s;
  }
  // fullscreen
  .plyr__controls > :nth-child(9) {
    grid-area: f;
  }
}

// .animate-spin {
//   animation-name: spin;
//   animation-duration: 1000ms;
//   animation-iteration-count: infinite;
//   animation-timing-function: linear;
// }

// @keyframes spin {
//   from {
//     transform: rotate(0deg);
//   }
//   to {
//     transform: rotate(360deg);
//   }
// }
