.adminInput {
  width: 100%;
  height: auto;

  & > label > span {
    display: block;
    font-family: var(--ptSans);
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 21px;
    display: flex;
    align-items: center;
  }

  & > label > div > img {
    display: flex;
    object-fit: cover;
  }

  & > label > input,
  & > label > div {
    width: 100%;
    min-width: 400px;
    height: 45px;
    background: #ffffff;
    border: 1px solid var(--borderOutlineColor);
    box-sizing: border-box;
    border-radius: var(--borderRadius, 4px);
    outline: none;
    padding: 0 15px;
    display: flex;
    align-items: center;
    gap: 15px;

    &::placeholder {
      font-family: PT Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 21px;
      display: flex;
      align-items: center;
      color: var(--placeholderColor);
    }
  }
  & > label > input:focus {
    border-color: var(--primaryColor);
    border-width: 2px;
  }
}
