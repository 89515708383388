.adminSite {
  width: 100%;
  height: auto;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 1rem;

  &.youtubeVideo {
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  }

  &__affiliation {
    display: flex;
    gap: 1rem;
    margin: 2rem 0;

    & > .adminInput {
      width: min-content;
    }

    & > div {
      align-self: baseline;
    }
  }

  &__banner {
    position: relative;
    border: 1px solid var(--borderOutlineColor);
    padding: 0.5rem;
    border-radius: var(--borderRadius, 5px);

    & > div {
      position: absolute;
      top: 5px;
      right: 5px;

      & > button {
        width: 30px;
        height: 30px;
        border: none;
        outline: none;
        background: var(--borderOutlineColor);
        border-radius: var(--borderRadius, 5px);
      }
    }
  }

  & > label {
    background: var(--borderOutlineColor);
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
    cursor: pointer;
  }

  &__testimonials {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 1rem;

    & > div {
      border: 1px solid var(--borderOutlineColor);
      background: #fff;
      padding: 0.5rem 1rem;
      border-radius: var(--borderRadius, 2px);
      position: relative;

      & > div {
        position: absolute;
        top: 0;
        right: 0;

        & > button {
          width: 40px;
          height: 40px;
          border: none;
          outline: none;
          background: transparent;
        }
      }

      & > img {
        width: 65px;
        height: 65px;
        margin-bottom: 1rem;
      }

      & > p {
        margin-bottom: 0.1rem;
        word-break: break-all;
      }
    }
  }
}
