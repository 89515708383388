.adminBtn {
  background: var(--primaryColor);
  border-radius: var(--borderRadius, 4px);
  border: 1px solid var(--primaryColor);
  outline: none;
  font-size: 15px;
  font-family: var(--ptSans);
  color: #ffffff;
  font-weight: bold;
  padding: 10px 25px;
  font-style: normal;

  &.secondary {
    background: #ffffff;
    border-color: var(--borderOutlineColor);
    color: initial;
  }

  &.sm {
    font-size: 12px;
    padding: 5px 10px;
  }
}
