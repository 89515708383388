.adminDropdown {
  width: auto;
  height: auto;
  display: flex;
  justify-content: flex-end;
  position: relative;

  &__trigger {
    cursor: pointer;
    width: max-content;
  }

  &__options {
    position: absolute;
    top: calc(100% + 10px);
    min-width: 200px;
    opacity: 0;
    pointer-events: none;
    z-index: 2;

    & > div {
      border: 1px solid var(--borderOutlineColor);
      background: #fff;
      padding: 5px 0;
      border-radius: var(--borderRadius, 12px);
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.25);
    }

    &.active {
      opacity: 1;
      pointer-events: all;
    }
  }
}
