.adminTabs {
  width: 100%;
  height: auto;

  &__options {
    display: flex;
    gap: 1rem;

    & > button {
      background: #ffffff;
      border: 1px solid var(--borderOutlineColor);
      box-sizing: border-box;
      border-radius: var(--borderRadius, 4px);
      outline: none;
      cursor: pointer;
      padding: 5px 30px;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 21px;
      display: flex;
      align-items: center;
      text-align: center;
      color: #333333;

      &.active {
        background: var(--primaryShadeColor);
        color: var(--primaryColor);
      }
    }
  }
}
