iframe {
  border: 0;
  width: 100% !important;
  // height: 30rem !important;
}

// .ckEditor-readOnly {
//   .ck .ck-editor__top {
//     display: none !important;
//   }
//   .ck.ck-editor__main > .ck-editor__editable:not(.ck-focused) {
//     border: none !important;
//   }
// }
